@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;700&family=Source+Sans+Pro:wght@400;600&display=swap');

body {
  font-family: 'Source Sans Pro', sans-serif;
  line-height: 1.6;
  color: #333;
  max-width: 1400px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f5f5f5;
}

.App-header {
  text-align: center;
  margin-bottom: 40px;
}

h1, h2, h3 {
  font-family: 'Playfair Display', serif;
  color: #2c3e50;
}

h1 {
  font-size: clamp(2rem, 5vw, 3rem);
  margin-bottom: 20px;
}

h2 {
  font-size: clamp(1.5rem, 4vw, 2.5rem);
  margin-bottom: 15px;
}

h3 {
  font-size: clamp(1.2rem, 3vw, 2rem);
  margin-bottom: 10px;
}

.book-list ul {
  list-style-type: none;
  padding: 0;
}

.book-list li {
  margin-bottom: 10px;
}

.book-list a {
  text-decoration: none;
  color: #3498db;
  font-weight: 600;
  transition: color 0.3s ease;
}

.book-list a:hover {
  color: #2980b9;
}

.book-details {
  background-color: white;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.summary-control {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-bottom: 20px;
}

.summary-buttons {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.summary-button {
  padding: 10px 20px;
  background-color: #e9ecef;
  border: none;
  border-radius: 4px;
  color: #495057;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.summary-button:hover {
  background-color: #ced4da;
}

.summary-button.active {
  background-color: #007bff;
  color: white;
}

.summary {
  margin-top: 30px;
  font-size: 1.1rem;
  line-height: 1.8;
}

.summary p {
  white-space: pre-line;
}

.metadata {
  background-color: #f8f9fa;
  padding: 15px;
  border-radius: 5px;
  margin-bottom: 20px;
}

.summary-control {
  background-color: #e9ecef;
  padding: 20px;
  border-radius: 5px;
  margin-bottom: 20px;
}

.summary-control h3 {
  margin-top: 0;
  color: #495057;
}

.slider-container {
  position: relative;
  width: 100%;
  margin: 20px 0;
}

input[type="range"] {
  width: 100%;
  -webkit-appearance: none;
  appearance: none;
  background: transparent;
} 

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background: #007bff;
  cursor: pointer;
  margin-top: -8px;
}

input[type="range"]::-webkit-slider-runnable-track {
  width: 100%;
  height: 4px;
  background: #ced4da;
  border-radius: 2px;
}

input[type="range"]:focus {
  outline: none;
}

input[type="range"]:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.25);
}

.summary-size-label {
  text-align: center;
  font-weight: bold;
  color: #495057;
}

.summary {
  background-color: #f8f9fa;
  padding: 20px;
  border-radius: 5px;
}

.summary h3 {
  margin-top: 0;
  color: #495057;
}

.summary p {
  white-space: pre-line;
  line-height: 1.6;
}

.back-button {
  display: inline-block;
  margin-bottom: 20px;
  padding: 10px 15px;
  background-color: #f8f9fa;
  color: #495057;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.back-button:hover {
  background-color: #e9ecef;
}

.summary h1, .summary h2, .summary h3, .summary h4, .summary h5, .summary h6 {
  margin-top: 1em;
  margin-bottom: 0.5em;
}

.summary p {
  margin-bottom: 1em;
}

.summary ul, .summary ol {
  margin-bottom: 1em;
  padding-left: 2em;
}

.summary blockquote {
  border-left: 4px solid #ced4da;
  padding-left: 1em;
  margin-left: 0;
  color: #6c757d;
}

.summary code {
  background-color: #e9ecef;
  padding: 0.2em 0.4em;
  border-radius: 3px;
}

.summary pre {
  background-color: #e9ecef;
  padding: 1em;
  border-radius: 5px;
  overflow-x: auto;
}

.book-list {
  max-width: 100%;
  margin: 0 auto;
  padding: 20px;
}

.book-list-title {
  font-size: clamp(1.8rem, 4vw, 2.5rem);
  color: #2c3e50;
  text-align: center;
  margin-bottom: 40px;
}

.book-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;
}

.book-item {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  text-decoration: none;
  color: inherit;
}

.book-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.book-info {
  padding: 15px;
}

.book-title {
  font-size: clamp(1rem, 2.5vw, 1.4rem);
  margin: 0 0 10px;
  color: #2c3e50;
  line-height: 1.3;
}

.book-author {
  font-size: clamp(0.8rem, 2vw, 1rem);
  color: #7f8c8d;
  margin: 0;
}

.audio-player {
  background-color: #f8f9fa;
  padding: 20px;
  border-radius: 5px;
  margin-bottom: 20px;
}

.audio-player h3 {
  margin-top: 0;
  font-size: 1.2rem;
  color: #495057;
}

.audio-player audio {
  width: 100%;
  margin-top: 10px;
}

.audio-note {
  font-size: 0.8rem;
  color: #6c757d;
  margin-top: 5px;
  text-align: right;
}

.feedback-banner {
  background-color: #f0f0f0;
  padding: 8px 0;
  text-align: center;
  font-size: 0.9rem;
  border-bottom: 1px solid #ddd;
}

.feedback-banner a {
  color: #3498db;
  text-decoration: none;
  font-weight: bold;
}

.feedback-banner a:hover {
  text-decoration: underline;
}

@media screen and (min-width: 768px) {
  .book-grid {
    grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
  }
}

@media screen and (min-width: 1024px) {
  .book-grid {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  }
}

@media screen and (min-width: 1400px) {
  .book-grid {
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  }
}

@media (max-width: 480px) {
  .summary-buttons {
    flex-direction: column;
    align-items: center;
  }

  .summary-button {
    width: 100%;
    max-width: 200px;
  }
}

